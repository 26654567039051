import React from "react"
import { InvestmentsContainer } from "./investmentsStyle"
import { Col, Container, Row } from "react-bootstrap"
import InvestmentsItem from "./investmentsItem"
import janowopark2 from "../../images/janowo-2/model_OJP2_ujecie192-2.jpg"
import wiczlino2img from "@/images/z-8/OGWII-inwestycje.jpg"
import janowopark from "../../images/slide1.png"
import puckImage from "@/images/puck/z16-visual-tablet.jpg"
// import ojp3Image from "@/images/z-17/Janowo_Park_III_01_V04_wiz.jpg"
import ojp3Image from "@/images/z-20/Janowo_Park_III_ujecie_01-tablet.jpg"
import { injectIntl, Link } from "gatsby-plugin-intl"

const Investments = ({ intl }) => {
  const JanowoPark = {
    name: intl.formatMessage({ id: "estate.name.janowoPark" }),
    sizes: (
      <span>
        {intl.formatMessage({ id: "home.investment.janowo.content.mobile" })}
        <sup>2</sup>
      </span>
    ),
    descriptionDesktop: (
      <p>
        <strong>
          {intl.formatMessage({ id: "home.investment.janowo.content.bold" })}
        </strong>
        {intl.formatMessage({ id: "home.investment.janowo.content" })}
      </p>
    ),
    location: intl.formatMessage({ id: "estate.city.rumia" }),
    img: janowopark,
  }
  const JanowoParkTwo = {
    name: intl.formatMessage({ id: "estate.name.janowoParkTwo" }),
    sizes: (
      <span>
        {intl.formatMessage({ id: "home.investment.janowotwo.content.mobile" })}
        <sup>2</sup>
      </span>
    ),
    descriptionDesktop: (
      <p>
        <strong>
          {intl.formatMessage({ id: "home.investment.janowotwo.content.bold" })}
        </strong>
        {intl.formatMessage({ id: "home.investment.janowotwo.content" })}
      </p>
    ),
    location: intl.formatMessage({ id: "estate.city.rumia" }),
    img: janowopark2,
    position: "80% center",
  }
  const Wiczlino2 = {
    name: intl.formatMessage({ id: "estate.name.wiczlinotwo" }),
    sizes: (
      <span>
        {intl.formatMessage({
          id: "home.investment.wiczlinotwo.content.mobile",
        })}
        <sup>2</sup>
      </span>
    ),
    descriptionDesktop: (
      <p>
        <strong>
          {intl.formatMessage({
            id: "home.investment.wiczlinotwo.content.bold",
          })}
        </strong>
        {intl.formatMessage({ id: "home.investment.wiczlinotwo.content" })}
      </p>
    ),
    location: intl.formatMessage({ id: "estate.city.gdynia" }),
    img: wiczlino2img,
  }

  const Puck = {
    name: "Osiedle Pogodne",
    sizes: <span>II Kwartał 2025</span>,
    descriptionDesktop: (
      <p>
        <strong>
          {intl.formatMessage({ id: "home.investment.puck.content.bold" })}
        </strong>
        {intl.formatMessage({ id: "home.investment.puck.content" })}
      </p>
    ),
    location: intl.formatMessage({ id: "estate.city.puck" }),
    img: puckImage,
  }
  const Ojp3 = {
    name: "Osiedle Janowo Park III",
    sizes: <span>IV Kwartał 2025</span>,
    descriptionDesktop: (
      <p>
        <strong>
          Zamieszkaj na doskonale skomunikowanym osiedlu na granicy Rumi i Gdyni
        </strong>
        Osiedle Janowo Park III powstało z myślą o spełnieniu oczekiwań nawet
        najbardziej wymagających klientów. Nasze wieloletnie doświadczenie
        pozwoliło stworzyć miejsce, które łączy nowoczesność z harmonią
        otaczającej natury.
      </p>
    ),
    location: intl.formatMessage({ id: "estate.city.rumia" }),
    img: ojp3Image,
    position: "80% center",
  }
  return (
    <InvestmentsContainer>
      <Container>
        <Row>
          <Col xs="12" className={"no-padding"}>
            <div className="header">
              <h1>{intl.formatMessage({ id: "home.investment.title" })}</h1>
              <p>
                {intl.formatMessage({ id: "home.investment.content.bold" })}
                <span>
                  {intl.formatMessage({ id: "home.investment.content" })}
                </span>
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="6" lg="6" className={"custom-padding"}>
            <Link to={"/oferta/osiedle-janowo-park-3"}>
              <InvestmentsItem
                estateName={Ojp3.name}
                status="inProgress"
                flatSize={Ojp3.sizes}
                estate={Ojp3}
              />
            </Link>
          </Col>
          <Col xs="12" sm="12" md="6" lg="6" className={"custom-padding"}>
            <Link to={"/oferta/osiedle-pogodne-w-pucku"}>
              <InvestmentsItem
                estateName={Puck.name}
                status="inProgress"
                flatSize={Puck.sizes}
                estate={Puck}
                blur
              />
            </Link>
          </Col>

          <Col xs="12" sm="12" md="6" lg="6" className={"custom-padding"}>
            <Link to={"/oferta/osiedle-gdynia-wiczlino-2"}>
              <InvestmentsItem
                estateName={Wiczlino2.name}
                status="inProgress"
                flatSize={Wiczlino2.sizes}
                estate={Wiczlino2}
              />
            </Link>
          </Col>
          <Col xs="12" sm="12" md="6" lg="6" className={"custom-padding"}>
            <Link to={"/oferta/osiedle-janowo-park-2"}>
              <InvestmentsItem
                estateName={JanowoParkTwo.name}
                status="completed"
                flatSize={JanowoParkTwo.sizes}
                estate={JanowoParkTwo}
              />
            </Link>
          </Col>

          {/*
          <Col xs="12" sm="12" md="6" lg="6" className={"custom-padding"}>
            <Link to={"/oferta/osiedle-janowo-park"}>
              <InvestmentsItem
                estateName={JanowoPark.name}
                status="done"
                flatSize={JanowoPark.sizes}
                estate={JanowoPark}
              />
            </Link>
          </Col>
          */}
        </Row>
      </Container>
    </InvestmentsContainer>
  )
}

export default injectIntl(Investments)
